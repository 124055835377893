import {
	Visibility,
	VisibilityOff,
	CheckCircleOutlined as CheckCircleOutlinedIcon,
	HighlightOffOutlined as HighlightOffOutlinedIcon,
} from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ValidatedTextField } from "~/components/validated-form/validated-text-field.tsx";
import { useValidatedControlField } from "~/hooks/use-validated-control-field.ts";

export interface Props {
	labelTranslationKey: string;
	name: string;
	helperText?: string;
	required?: boolean;
	hasPasswordStrength?: boolean;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
export const PasswordField = ({
	labelTranslationKey,
	name,
	required,
	hasPasswordStrength,
	onKeyDown,
}: Props) => {
	const [showPassword, setShowPassword] = useState(false);
	const { t } = useTranslation();
	const [password, setPassword] = useValidatedControlField<string | null>(name);
	return (
		<div>
			<ValidatedTextField
				name={name}
				required={required}
				fullWidth
				type={showPassword ? "text" : "password"}
				labelTranslationKey={labelTranslationKey}
				hideErrorText={hasPasswordStrength}
				value={password ?? ""}
				onChange={(event) => {
					setPassword(event.target.value);
				}}
				onKeyDown={onKeyDown}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label={t("togglePasswordVisibility")}
								onClick={() => {
									setShowPassword((show) => {
										return !show;
									});
								}}
								onMouseDown={(event) => {
									event.preventDefault();
								}}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{hasPasswordStrength && <PasswordStrength password={password} />}
		</div>
	);
};

interface PasswordStrengthProps {
	password: string | null;
}

const PasswordStrength = ({ password }: PasswordStrengthProps) => {
	const parsedPassword = password ?? "";
	const hasUpperCase = /[A-Z]/.test(parsedPassword);
	const hasLowerCase = /[a-z]/.test(parsedPassword);
	const hasNumber = /[0-9]/.test(parsedPassword);
	const isLongEnough = parsedPassword.length >= 8;
	return (
		<div className="mb-2 mt-1 flex flex-col gap-0.5">
			<PasswordCondition
				condition={hasUpperCase}
				translationKey="oneUpperCase"
			/>
			<PasswordCondition
				condition={hasLowerCase}
				translationKey="oneLowerCase"
			/>
			<PasswordCondition condition={hasNumber} translationKey="oneNumber" />
			<PasswordCondition
				condition={isLongEnough}
				translationKey="atLeast8Characters"
			/>
		</div>
	);
};

const PasswordCondition = ({
	condition,
	translationKey,
}: {
	condition: boolean;
	translationKey: string;
}) => {
	const { t } = useTranslation();
	return (
		<div className="ml-2 mt-1 flex gap-1 text-sm">
			{condition ? (
				<CheckCircleOutlinedIcon
					color="success"
					fontSize="small"
					aria-label={`${t(translationKey)} ${t("existing")}`}
				/>
			) : (
				<HighlightOffOutlinedIcon
					fontSize="small"
					aria-label={`${t(translationKey)} ${t("missing")}`}
				/>
			)}
			{t(translationKey)}
		</div>
	);
};
